<template>
    <div class="map">
        <l-map style="height: 600px" :zoom="zoom" :center="center">
            <l-tile-layer :url="url" :attribution="localAttribution"></l-tile-layer>
            <template v-for="(mark, index) in marker">
                <l-marker
                    v-if="mark.link || mark.isUser"
                    :key="index"
                    :lat-lng="mark.marker"
                    @click="scrollToBlock(mark.link)"
                    :icon="mark.isUser ? userIcon : icon"
                ></l-marker>

                <l-marker
                    v-else
                    :key="index + 1"
                    :lat-lng="mark"
                    :icon="icon"
                ></l-marker>
            </template>
        </l-map>
    </div>
</template>
<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
    name: 'MapEnabled',
    props: {
        zoom: {
            type: Number,
            default: 0
        },
        center: {
            type: Array,
            default: () => []
        },
        url: {
            type: String,
            default: ''
        },
        attribution: {
            type: String,
            default: ''
        },
        marker: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            icon: {},
            userIcon: {}
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    methods: {
        scrollToBlock(blockId) {
            this.$emit('click-on-marker', blockId)
        }
    },
    computed:{
        localAttribution(){
            return this.attribution+'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
    },
    mounted() {
        this.icon = icon({
            iconUrl: '/blocks/icons/LeafletMarker.svg',
            iconSize: [32, 32]
        })
        this.userIcon = icon({
            iconUrl: '/blocks/icons/LeafletMarkerUser.svg',
            iconSize: [32, 32]
        })
    }
}
</script>
<style lang="scss" scoped>
.map {
    position: relative;
    &:hover {
        .over-div {
            opacity: 0;
            z-index: -1 !important;
        }
    }
    @media (max-width: 767px) {
        &:hover {
            .over-div {
                opacity: 1;
                z-index: 1 !important;
            }
        }
    }
}

// Style to the leaflet
.vue2leaflet-map {
    position: relative;
    z-index: 1 !important;
    @media (max-width: 767px) {
        height: 400px !important;
    }
}
</style>
